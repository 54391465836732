import {gql} from "@apollo/client";

export const searchJobById = gql`
    query SearchJobById($jobId: Int!) {
        searchJobById(jobId: $jobId) {
            jobId
            jobTitle
            payRateMinHourlyRate
            payRateMaxHourlyRate
            jobCode
        }
    }
`;

export const searchJobByTitle = gql`
    query SearchJobByTitle($jobTitle: String!) {
        searchJobByTitle(jobTitle: $jobTitle) {
            jobId
            jobTitle
            payRateMinHourlyRate
            payRateMaxHourlyRate
            jobCode
        }
    }
`;

export const searchJobByCategory = gql`
    query SearchJobByCategory($jobCategory: Int!) {
        searchJobByCategory(jobCategory: $jobCategory) {
            jobId
            jobTitle
            payRateMinHourlyRate
            payRateMaxHourlyRate
            jobCode
        }
    }
`;

export const searchJobByCode = gql`
    query SearchJobByONETCode($jobONETCode: String!) {
        searchJobByONETCode(jobONETCode: $jobONETCode) {
            jobId
            jobTitle
            payRateMinHourlyRate
            payRateMaxHourlyRate
            jobCode
        }
    }
`;

export const searchJobByTitleCategory = gql`
    query  SearchJobByTitleCategory($jobTitle: String!, $jobCategory: Int!) {
        searchJobByTitleCategory(jobTitle: $jobTitle, jobCategory: $jobCategory) {
            jobId
            jobTitle
            payRateMinHourlyRate
            payRateMaxHourlyRate
            jobCode
        }
    }
`;

export const getAllJobCategories = gql`
    query GetAllJobCategories {
        getJobCategoryList {
            label
            value
        }
    }
`;

export const getJobDetailById = gql`
    query SearchJobById($jobId: Int!) {
        searchJobById(jobId: $jobId) {
            jobId
            effectiveDate
            statusId
            status
            jobTitle
            payRateIndicator
            payRateMinStep
            payRateMaxStep
            payRateMinHourlyRate
            payRateMaxHourlyRate
            isBenchmark
            benchmarkJobId
            benchmarkJobTitle
            benchmarkFamilyId
            benchmarkFamilyTitle
            equalOpportunityDesignationId
            equalOpportunityDesignation
            probationMonths
            fairLaborStandardsStatusId
            flsaStatus
            classified
            workingLevelId
            workingLevel
            physicalRequirementId
            physicalRequirement
            justification
            purpose
            workingConditionId
            workingCondtion
            safetySensitiveId
            safetySensitive
            supervisorLevelId
            supervisorLevel
            createdBy
            createdDate
            jobCode
        jobCategory {
            categoryId
            name
        }
        jobKSA {
            sortOrder
            skillBankId
            description
            onetElementId
            onetItem
            skillCategoryId
            category
            categoryName
            subcategory
        }
        jobTask {
            sortOrder
            permanent
            taskBankId
            taskDescription
        }
        jobAgency {
            agencyId
            name
        }
        jobOther {
            jobOtherRequirementId
            otherTitle
            otherComment
        }
    }
}
`;