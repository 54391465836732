import React, {useState, useEffect} from "react";
import {
    Button,
    TextInput,
    Size,
    PopOver,
    SelectInput,
    ValidationStyle,
    MaskType, AjaxSpinner
} from "@dts-soldel/dts-react-common";
import Icon from "../../misc/Icon";
import {action} from "mobx";
import useRootStoreContext from "../../app/store/useRootStoreContext";
import {observer} from "mobx-react";
import {isEmpty} from "lodash";
import useRefLazy from "../../misc/hooks/useRefLazy";
import formValidationEngine from "../Misc/FormValidationEngine";
import InputMask from "react-input-mask";
import {SearchType} from "../../misc/enums/constants";
import popOverTypes from "@dts-soldel/dts-react-common/components/pop-over/popOverTypes";
import jobService from "../../app/services/JobService";
import {
    searchJobById,
    searchJobByTitle,
    searchJobByCode,
    searchJobByCategory,
    searchJobByTitleCategory,
    getAllJobCategories
} from "../../app/webservice/gql/JobServiceGQL";
import {useLazyQuery} from '@apollo/client';
import useKeyDownEvent from "../hooks/useKeyDownEvent";

const propTypes = {};
const defaultProps = {};


const HomeSearch = () => {
        const {UiStore} = useRootStoreContext();
        const onetSocCodeRegex = /^\d{2}-\d{4}.\d{2}$/;

        const [errorMessage, setErrorMessage] = useState(undefined);
        const [validationStyle, setValidationStyle] = useState(ValidationStyle.ALWAYS);
        const [getCategoryData, {
            called: categoryCalled,
            loading: categoryLoading,
            data: categoryData
        }] = useLazyQuery(getAllJobCategories);
        const [getJobByIdData, {
            called: jobByIdCalled,
            loading: jobByIdLoading,
            data: jobByIdData
        }] = useLazyQuery(searchJobById, {fetchPolicy: "no-cache"});
        const [getJobByTitleData, {
            called: jobByTitleCalled,
            loading: jobByTitleLoading,
            data: jobByTitleData
        }] = useLazyQuery(searchJobByTitle, {fetchPolicy: "no-cache"});
        const [getJobByCategoryData, {
            called: jobByCategoryCalled,
            loading: jobByCategoryLoading,
            data: jobByCategoryData
        }] = useLazyQuery(searchJobByCategory, {fetchPolicy: "no-cache"});
        const [getJobByCodeData, {
            called: jobByCodeCalled,
            loading: jobByCodeLoading,
            data: jobByCodeData
        }] = useLazyQuery(searchJobByCode, {fetchPolicy: "no-cache"});
        const [getJobByTitleCategoryData, {
            called: jobByTitleCategoryCalled,
            loading: jobByTitleCategoryLoading,
            data: jobByTitleCategoryData
        }] = useLazyQuery(searchJobByTitleCategory, {fetchPolicy: "no-cache"});

        const [disableJobId, setDisableJobId] = useState(true);
        const [disableJobTitle, setDisableJobTitle] = useState(true);
        const [disableJobCategory, setDisableJobCategory] = useState(true);
        const [disableJobOnet, setDisableJobOnet] = useState(false);
        const [hideJobCategoryList, setHideJobCategoryList] = useState(true);

        const [allJobCategories, setAllJobCategories] = useState([]);

        useEffect(() => {
            if (!categoryData) {
                getCategoryData();
            }

            if (categoryData) {
                setAllJobCategories(categoryData.getJobCategoryList);
                setHideJobCategoryList(false);
                UiStore.categoryListLoading = false;
            }
        }, [categoryData]);

        useEffect(() => {
            // if (UiStore.selectedJobId && UiStore.selectedJobTitle && UiStore.selectedJobCategory && UiStore.selectedJobOnet) {
            if (UiStore.selectedJobId) {
                setDisableJobTitle(true);
                setDisableJobCategory(true);
                setDisableJobOnet(true);
            } else {
                setDisableJobTitle(false);
                setDisableJobCategory(false);
                setDisableJobOnet(false);
            }
        }, [UiStore.selectedJobId]);

        useEffect(() => {
            if (UiStore.selectedJobOnet) {
                setDisableJobTitle(true);
                setDisableJobCategory(true);
                setDisableJobId(true);
            } else {
                setDisableJobTitle(false);
                setDisableJobCategory(false);
                setDisableJobId(false);
            }
        }, [UiStore.selectedJobOnet]);

        useEffect(() => {
            if (UiStore.selectedJobTitle || UiStore.selectedJobCategory) {
                setDisableJobId(true);
                setDisableJobOnet(true);
            } else {
                setDisableJobId(false);
                setDisableJobOnet(false);
            }
        }, [UiStore.selectedJobTitle, UiStore.selectedJobCategory]);

        useEffect(() => {
            UiStore.resultsLoading = jobByIdLoading;

            if (jobByIdData && jobByIdData.searchJobById.length > 0) {
                UiStore.lastSearchResults = [];
                UiStore.results = jobByIdData.searchJobById;
                UiStore.selectedSortBy = 1;
                updateUiStore();
            } else if (!jobByIdData && jobByIdCalled) {
                UiStore.searchResultsMessage = getSearchErrorMessageBySearchType();
                if (UiStore.results.length > 0) {
                    UiStore.lastSearchResults = UiStore.results;
                }
                UiStore.searchResultsFound = false;
                UiStore.showNoJobFoundMessage = true;
            }
        }, [jobByIdLoading, jobByIdData]);

        useEffect(() => {
            UiStore.resultsLoading = jobByTitleLoading;

            if (jobByTitleData && jobByTitleData.searchJobByTitle.length > 0) {
                UiStore.lastSearchResults = [];
                UiStore.results = jobByTitleData.searchJobByTitle;
                UiStore.selectedSortBy = 2;
                updateUiStore();
            } else if (!jobByTitleLoading && jobByTitleCalled) {
                UiStore.searchResultsMessage = getSearchErrorMessageBySearchType();
                if (UiStore.results.length > 0) {
                    UiStore.lastSearchResults = UiStore.results;
                }
                UiStore.searchResultsFound = false;
                UiStore.showNoJobFoundMessage = true;
            }
        }, [jobByTitleLoading, jobByTitleData]);

        useEffect(() => {
            UiStore.resultsLoading = jobByCategoryLoading;

            if (jobByCategoryData && jobByCategoryData.searchJobByCategory.length > 0) {
                UiStore.lastSearchResults = [];
                UiStore.results = jobByCategoryData.searchJobByCategory;
                UiStore.selectedSortBy = 1;
                updateUiStore();
            } else if (!jobByCategoryLoading && jobByCategoryCalled) {
                UiStore.searchResultsMessage = getSearchErrorMessageBySearchType();
                if (UiStore.results.length > 0) {
                    UiStore.lastSearchResults = UiStore.results;
                }
                UiStore.searchResultsFound = false;
                UiStore.showNoJobFoundMessage = true;
            }
        }, [jobByCategoryLoading, jobByCategoryData]);

        useEffect(() => {
            UiStore.resultsLoading = jobByCodeLoading;

            if (jobByCodeData && jobByCodeData.searchJobByONETCode.length > 0) {
                UiStore.lastSearchResults = [];
                UiStore.results = jobByCodeData.searchJobByONETCode;
                UiStore.selectedSortBy = 4;
                updateUiStore();
            } else if (!jobByCodeLoading && jobByCodeCalled) {
                UiStore.searchResultsMessage = getSearchErrorMessageBySearchType();
                if (UiStore.results.length > 0) {
                    UiStore.lastSearchResults = UiStore.results;
                }
                UiStore.searchResultsFound = false;
                UiStore.showNoJobFoundMessage = true;
            }
        }, [jobByCodeLoading, jobByCodeData]);

        useEffect(() => {
            UiStore.resultsLoading = jobByTitleCategoryLoading;

            if (jobByTitleCategoryData && jobByTitleCategoryData.searchJobByTitleCategory.length > 0) {
                UiStore.lastSearchResults = [];
                UiStore.results = jobByTitleCategoryData.searchJobByTitleCategory;
                UiStore.selectedSortBy = 2;
                updateUiStore();
            } else if (!jobByTitleCategoryLoading && jobByTitleCategoryCalled) {
                UiStore.searchResultsMessage = getSearchErrorMessageBySearchType();
                if (UiStore.results.length > 0) {
                    UiStore.lastSearchResults = UiStore.results;
                }
                UiStore.searchResultsFound = false;
                UiStore.showNoJobFoundMessage = true;
            }
        }, [jobByTitleCategoryLoading, jobByTitleCategoryData]);

        const validationEngine = useRefLazy(formValidationEngine).current;
        validationEngine.setValidationStyle(validationStyle);
        let isValid = validationEngine.isValid(UiStore);

        const updateUiStore = action(() => {
            UiStore.searchResultsFound = true;
            UiStore.showResultsFirstPage = true;
            UiStore.resultsLoading = false;
            UiStore.showNoJobFoundMessage = false;
            hideSearchBy();
            UiStore.lastViewedJobPage = 0;
            UiStore.lastMaxPage = 0;
            UiStore.lastMinPage = 0;
        });

        const doReset = action(() => {
            // reset search criteria input
            UiStore.selectedJobId = "";
            UiStore.selectedJobTitle = "";
            UiStore.selectedJobCategory = null;
            UiStore.selectedJobOnet = "";

            UiStore.resetSearchBy = true;

            setValidationStyle(ValidationStyle.ALWAYS);

            resetError();
        });

        const resetError = action(() => {
            setErrorMessage("");
            UiStore.searchResultsMessage = "";
        });

        const doSearch = action(() => {

            if (isEmpty(UiStore.selectedJobId) && isEmpty(UiStore.selectedJobTitle) && UiStore.selectedJobCategory == null && isEmpty(UiStore.selectedJobOnet)
            ) {
                setErrorMessage("Enter Job Id, Title/Category or Onet");
            } else if (isValid) {
                setErrorMessage("");
                UiStore.showWelcomeMessage = false;
                UiStore.searchResultsFound = false;
                UiStore.resetSearchBy = false;
                UiStore.searchType = SearchType.NO_SEARCH;
                UiStore.searchResultsMessage = "";
                doSubmit();
            }
        });

        const doSubmit = action(() => {

            // search
            if (UiStore.selectedJobId) {
                UiStore.searchType = SearchType.JOB_ID;

                getJobByIdData({
                    variables: {
                        jobId: parseInt(UiStore.selectedJobId)
                    }
                });

            } else if (
                !isEmpty(UiStore.selectedJobTitle) && UiStore.selectedJobCategory == null && isEmpty(UiStore.selectedJobOnet)
            ) {
                UiStore.searchType = SearchType.JOB_TITLE;
                getJobByTitleData({
                    variables: {
                        jobTitle: UiStore.selectedJobTitle
                    }
                });

            } else if (
                isEmpty(UiStore.selectedJobTitle) && UiStore.selectedJobCategory != null && isEmpty(UiStore.selectedJobOnet)
            ) {
                UiStore.searchType = SearchType.JOB_CATEGORY;
                getJobByCategoryData({
                    variables: {
                        jobCategory: UiStore.selectedJobCategory
                    }
                });

            } else if (
                isEmpty(UiStore.selectedJobId) && isEmpty(UiStore.selectedJobTitle) && UiStore.selectedJobCategory == null && !isEmpty(UiStore.selectedJobOnet)
            ) {
                // if (UiStore.selectedJobOnet.match(onetSocCodeRegex)) {
                UiStore.searchType = SearchType.JOB_ONET_CODE;
                getJobByCodeData({
                    variables: {
                        jobONETCode: UiStore.selectedJobOnet
                    }
                });
                // } else {

                // }
            } else if (
                !isEmpty(UiStore.selectedJobTitle) && UiStore.selectedJobCategory != null && isEmpty(UiStore.selectedJobOnet)
            ) {
                UiStore.searchType = SearchType.JOB_TITLE_CATEGORY;
                getJobByTitleCategoryData({
                    variables: {
                        jobTitle: UiStore.selectedJobTitle,
                        jobCategory: parseInt(UiStore.selectedJobCategory)
                    }
                });
            }
        });

        const doEnterKeySearch = (e) => {
            // alert(`you pressed key: `);
            doSearch();
        };

        useKeyDownEvent({
            whichKeyCode: 'Enter',
            onKeyDown: doEnterKeySearch
        });

        const getSearchErrorMessageBySearchType = () => {
            switch (UiStore.searchType) {
                case SearchType.NO_SEARCH:
                    return null;

                case SearchType.JOB_ID:
                    return (
                        `No matching job(s) found for job id ${UiStore.selectedJobId}`
                    );

                case SearchType.JOB_TITLE:
                    return (
                        `No matching job(s) found for job title containing "${UiStore.selectedJobTitle}"`
                    );

                case SearchType.JOB_CATEGORY:
                    return (
                        `No matching job(s) found for job category "${getJobCategoryLabel(UiStore.selectedJobCategory)}"`
                    );

                case SearchType.JOB_ONET_CODE:
                    return (
                        `No matching job(s) found for job O*NET-SOC code "${UiStore.selectedJobOnet}"`
                    );

                case SearchType.JOB_TITLE_CATEGORY:
                    return (
                        `No matching job(s) found for job title contains "${UiStore.selectedJobTitle}" AND job category "${getJobCategoryLabel(UiStore.selectedJobCategory)}"`
                    );

                default:
                    break;
            }
        };

        const hideSearchBy = action(() => {
            UiStore.searchCollapsed = true;
        });

        const showSearchBy = action(() => {
            UiStore.searchCollapsed = false;
        });

        const getJobCategoryLabel = (jobCategoryValue) => {
            let jobCat = allJobCategories.find((jobCat) => jobCat.value === jobCategoryValue);
            if (jobCat) {
                return jobCat.label;
            }
        };

        return (
            <section>
                <h2 id="searchByTitle" className="search-section__title" onClick={showSearchBy}>Search
                    By {Icon.iconSearch()}</h2>
                <p>
                    Job descriptions can be looked up by entering a specific Job ID, Job
                    Title and/or Job Category or O*NET-SOC code.
                </p>
                <div className={
                    errorMessage
                        ? "form-error no-arrow"
                        : ""
                }>
                    <span>{errorMessage ? <p>{errorMessage}</p> : ""}</span>
                </div>
                <div className="search-section__content">
                    <TextInput
                        label="Job Id"
                        field="selectedJobId"
                        showLabel={true}
                        placeholder=""
                        onChange={(field, value) => {
                            (UiStore.selectedJobId = value);
                            resetError();
                        }}
                        size={Size.FULL_WIDTH}
                        value={UiStore.selectedJobId}
                        maskType={MaskType.INTEGER_POSITIVE}
                        maxLength={8}
                        validationEngine={validationEngine}
                        className={disableJobId ? "disabled" : ""}
                        disabled={disableJobId}
                    />
                    <p align="center">- OR -</p>
                    <TextInput
                        label="Job Title"
                        field="selectedJobTitle"
                        showLabel={true}
                        placeholder=""
                        onChange={(field, value) => {
                            (UiStore.selectedJobTitle = value);
                            resetError();
                        }}
                        size={Size.FULL_WIDTH}
                        value={UiStore.selectedJobTitle}
                        validationEngine={validationEngine}
                        className={disableJobTitle ? "disabled" : ""}
                        disabled={disableJobTitle}
                    />
                    {
                        hideJobCategoryList
                            ? <AjaxSpinner size={30}/>
                            : null
                    }
                    <SelectInput
                        label={hideJobCategoryList ? "Loading..." : "Job Category"}
                        field="selectedJobCategory"
                        // options={jobService.jobCategoryList}
                        options={allJobCategories}
                        value={UiStore.selectedJobCategory}
                        placeholder=""
                        onChange={(field, value) => {
                            (UiStore.selectedJobCategory = value);
                            resetError();
                        }}
                        //handle creation of new options here
                        size={Size.LARGE}
                        multiSelect={false}
                        validationEngine={validationEngine}
                        className={disableJobCategory || hideJobCategoryList ? "disabled" : ""}
                        disabled={disableJobCategory || hideJobCategoryList}
                    />

                    <div className={disableJobOnet ? "disabled input-container" : "input-container"}>
                        <label className="popover--icon"
                               htmlFor="selectedJobOnet">O*NET-SOC Code</label>

                        <PopOver
                            anchor={Icon.iconInfo()}
                            className="label--icon"
                            classNamePopup="elevation--z4"
                            dismissible={true}
                            focusable={false}
                            mode="click"
                            popOverType={popOverTypes.DIALOG}
                            position="top"
                        >
                            <span>O*NET-SOC codes are derived from the <br/> <a href="https://www.onetonline.org/"
                                                                                target="_blank">O*NET Online website {Icon.iconExternalLink()}</a></span>
                        </PopOver>

                        <InputMask
                            id="selectedJobOnet"
                            placeholder="00-0000.00"
                            type="text"
                            field="selectedJobOnet"
                            mask="99-9999.99"
                            maskChar="0"
                            alwaysShowMask={false}
                            value={UiStore.selectedJobOnet}
                            className="size-full"
                            disabled={disableJobOnet}
                            onChange={(e) => {
                                if (e.target.value !== "00-0000.00" && e.target.value !== "") {
                                    (UiStore.selectedJobOnet = e.target.value);
                                } else {
                                    (UiStore.selectedJobOnet = "");
                                }
                                resetError();
                            }}/>

                    </div>

                    <Button id="btnSearch" className="button--large mainSearch" label="Search" onClick={doSearch}/>
                    <Button className="button--large outline" label="Reset" onClick={doReset}/>
                </div>
            </section>
        );
    }
;

HomeSearch.propTypes = propTypes;
HomeSearch.defaultProps = defaultProps;

export default observer(HomeSearch);
