import { makeAutoObservable } from "mobx";

// putting window.utahHeader in mobx was causing mobx to try to treat utahheader as plain data and causing
// issues, so moving it to a global POJO keeps it in its natural form and the store reports if it is loaded
// or not.
// use the `useUtahHeader` hook to gain access to the header in your component which will trigger when the
// store changes (don"t forget to wrap your component in observer)
export const utahHeaderGlobal = { utahHeader: null };

export default class UtahHeaderStore {
    // true once the header script is requested
    isUtahHeaderScriptStarted = false;

    // true/false telling if the header variable exists on the window
    isUtahHeaderLoaded = false;

    // the config settings for the UtahHeader
    // utahHeaderConfig <---- don"t store config in store because it gets converted to mobX proxy and totally messes up sending it to the header
    // instead, use the useUtahHeaderConfigState() hook to play with the config and useUtahHeader() to get the utahHeader

    constructor(rootStore) {
        makeAutoObservable(this);
        Object.defineProperty(this, "rootStore", rootStore);
    }
}
