import React from "react";
import Pages from "../pages/Pages";
import {Route, Switch, withRouter} from "react-router";
import JobDetail from "../../pages/JobDetail/JobDetail";
import JobNotFound from "../../pages/Misc/JobNotFound";
import Page404 from "../../pages/Misc/Page404";
import Home from "../../pages/Home/Home";

const propTypes = {};
const defaultProps = {};

const AppRoutes = () => {

    let routes = (
        <Switch>
            <Route exact path="/jobNotFound/:jobId">
                <JobNotFound/>
            </Route>
            <Route exact path="/detail/:jobId">
                <JobDetail/>
            </Route>
            <Route exact path="/">
                <Home/>
            </Route>
            <Route>
                <Page404/>
            </Route>
        </Switch>
    );

    return (
        <main className="main-content-wrapper">
            {routes}
        </main>
    );
};

AppRoutes.propTypes = propTypes;
AppRoutes.defaultProps = defaultProps;

export default withRouter(AppRoutes);
