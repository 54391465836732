import { autorun } from "mobx";
import { useEffect, useRef, useState } from "react";
import { utahHeaderGlobal } from "../store/stores/UtahHeaderStore";
import useRootStoreContext from "../store/useRootStoreContext";
import UtahHeaderConfigDefault from "./UtahHeaderConfigDefault";

const propTypes = {};
const defaultProps = {};

const UtahHeader = () => {
    const { SecurityStore, UtahHeaderStore } = useRootStoreContext();
    const [utahHeaderConfig, setUtahHeaderConfig] = useState(null);
    const utahHeaderConfigRef = useRef();
    utahHeaderConfigRef.current = utahHeaderConfig;

    // load utah header script once in the life time of the app
    useEffect(() => autorun(() => {
        if (!UtahHeaderStore.isUtahHeaderScriptStarted) {
            UtahHeaderStore.isUtahHeaderScriptStarted = true;

            // start listening for header to be loaded BEFORE the request so it will for sure be listening when the script is loaded
            window.addEventListener("ugovHeaderLoaded", () => {
                utahHeaderGlobal.utahHeader = window.utahHeader;
                UtahHeaderStore.isUtahHeaderLoaded = true;
                setUtahHeaderConfig(UtahHeaderConfigDefault({
                    utahHeader: utahHeaderGlobal.utahHeader,
                }));
            });

            // add header bundle with current version (not necessarily latest)
            const script = document.createElement("script");
            const scriptUrl = `https://cdn.utah.gov/utah-header/latest/utahHeader.bundle.js`;
            script.setAttribute("src", scriptUrl);
            document.head.appendChild(script);
        }
    }), []);

    // on utah header info changes, update the header
    useEffect(() => autorun(() => {
        const givenName = SecurityStore.loggedInUser?.given_name;
        const familyName = SecurityStore.loggedInUser?.family_name;
        if (UtahHeaderStore.isUtahHeaderLoaded && utahHeaderConfigRef.current) {
            utahHeaderGlobal.utahHeader.setConfig(utahHeaderConfigRef.current);
        }
    }), [utahHeaderConfigRef.current]);

    return null;
};

UtahHeader.propTypes = propTypes;
UtahHeader.defaultProps = defaultProps;

export default UtahHeader;
