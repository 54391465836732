import {MessagePopupCore, OIDCOptions, AriaLiveContextProvider} from "@dts-soldel/dts-react-common";
import "core-js/stable";
import {observer} from "mobx-react";
import "mobx-react-lite/batchingForReactDom";
import React, {useEffect} from "react";
import {render} from "react-dom";
import {BrowserRouter, useHistory} from "react-router-dom";
import "../../css/index.scss";
import HeadTags from "./HeadTags";
import globalHistory from "./pages/globalHistory.js";
import AppRoutes from "./routes/AppRoutes";
import RootStoreContextProvider from "./store/RootStoreContextProvider";
import useRootStoreContext from "./store/useRootStoreContext";
import UtahHeader from "./UtahHeader/UtahHeader";
import AppApolloProvider from "./webservice/AppApolloProvider";

const propTypes = {};
const defaultProps = {};

const AppFunc = ({...props}) => {
    const {SecurityStore} = useRootStoreContext();
    globalHistory.history = useHistory();

    useEffect(() => {
        const reactDiv = document.getElementById("react");
        reactDiv.classList.add("mode-dark");
    }, []);

    return (
        <>
            <HeadTags/>
            <AppRoutes {...props} history={history}/>
            <MessagePopupCore/>
        </>
    );
};

AppFunc.propTypes = propTypes;
AppFunc.defaultProps = defaultProps;

const App = observer(AppFunc);

// for Service Portal
OIDCOptions.client_id = "unharmonious-vitrous-578546";
OIDCOptions.responseType = "code";

const div = document.getElementById("react");

render(
    (
        <RootStoreContextProvider>
            <BrowserRouter basename={"/"}>
                <AppApolloProvider>
                    <UtahHeader/>
                    <AriaLiveContextProvider numberAssertiveDivs={3} numberPoliteDivs={3}>
                        <App/>
                    </AriaLiveContextProvider>
                </AppApolloProvider>
            </BrowserRouter>
        </RootStoreContextProvider>
    ),
    div
);
