import { webserviceUrls } from "../../../appConfig.json";

// "-dev" comes from default firebase hostname
export default (
    (
        document.location.hostname.includes("localhost")
            ? webserviceUrls.local
            : document.location.hostname.includes(".dev.") || document.location.hostname.includes("-dev")
                ? webserviceUrls.dev
                : document.location.hostname.includes(".at.") || document.location.hostname.includes("-at")
                    ? webserviceUrls.at
                    : webserviceUrls.prod
    )
);
