import SecurityStore from "./stores/SecurityStore";
import UiStore from "./stores/UiStore";
import UtahHeaderStore from "./stores/UtahHeaderStore";
import { configure } from "mobx"

configure({
    enforceActions: "never",
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    observableRequiresReaction: false,
    disableErrorBoundaries: true
});

class RootStore {
    constructor() {
        this.SecurityStore = new SecurityStore(this);
        this.UiStore = new UiStore(this);
        this.UtahHeaderStore = new UtahHeaderStore(this);
    }
}

const rootStore = new RootStore();
export default rootStore;
