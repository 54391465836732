import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { action } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import useRootStoreContext from "../store/useRootStoreContext";
import currentWebservice from "../security/currentWebservice";

const propTypes = {
	children: PropTypes.node.isRequired,
};
const defaultProps = {};

const createClient = () => {
	const httpLink = createHttpLink({ uri: currentWebservice });

	// const authLink = setContext((_, { headers }) => ({
	// 	headers: {
	// 		...headers,
	// 		authorization: jwt ? `Bearer ${jwt}` : "",
	// 	}
	// }));

	return new ApolloClient({
		link: httpLink,
		cache: new InMemoryCache()
	});
};

const AppApolloProvider = ({ children }) => {
	// const { SecurityStore } = useRootStoreContext();
	// const securityStoreJwt = SecurityStore.jwt;
	const [apolloClient, setApolloClient] = useState(() => createClient());
	// const lastKnownJwtRef = useRef(null);


	// useEffect(action(() => {
	// 	// only resetup if changes occurred
	// 	if (SecurityStore.jwt && (!lastKnownJwtRef.current || (lastKnownJwtRef.current !== securityStoreJwt))) {
	// 		lastKnownJwtRef.current = SecurityStore.jwt;
	// 		setApolloClient(createClient(SecurityStore.jwt));
	// 	}
	// }), [SecurityStore.jwt]);

	return (
		apolloClient
			? (
				<ApolloProvider client={apolloClient}>
					{children}
				</ApolloProvider>
			)
			: children
	);
};

AppApolloProvider.propTypes = propTypes;
AppApolloProvider.defaultProps = defaultProps;

export default observer(AppApolloProvider);
