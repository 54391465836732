import { makeAutoObservable, computed, configure } from "mobx";
import {SearchType} from "../../../misc/enums/constants";

export default class UiStore {
  pageTitle = "DHRM Public Job Description";

  // search criteria entered by user
  selectedJobId = "";
  selectedJobTitle = "";
  selectedJobCategory = null;
  selectedJobOnet = "";

  selectedSortBy = 1;

  viewJobId = "";
  viewSalaryRange = "";
  viewOnet = "";
  viewTitle = "";

  // results of searching with criteria
  searchType = SearchType.NO_SEARCH;
  searchResultsFound = false;
  loadPrevSearchResults = false;
  resetSearchBy = false;
  resultsLoading = false;
  categoryListLoading = true;
  searchCollapsed = false;
  results = [];
  lastSearchResults = [];
  searchResults = [];

  lastViewedJobId = 0;
  lastViewedJobPage = 0;
  lastMaxPage = 0
  lastMinPage = 0
  showResultsFirstPage = false;

  searchResultsMessage = "";

  pageSize = 10;

  totalPages = 0;

  currentPage = 0;

  // show welcome message
  showWelcomeMessage = true;
  showNoJobFoundMessage = false;
  initializing = true;

  constructor(rootStore) {
    makeAutoObservable(this, {
      searchResultsCount: computed,
      lastSearchResultsCount: computed
    });
    Object.defineProperty(this, "rootStore", rootStore);
  }

  get searchResultsCount() {
    if (this.results) {
      return this.results.length;
    } else {
      return 0;
    }
  }

  get lastSearchResultsCount() {
    if (this.lastSearchResults) {
      return this.lastSearchResults.length;
    } else {
      return 0;
    }
  }
}
