class JobService {
    constructor() {

        this.sortBy = [{
                value: 1,
                label: "Job Id",
            },
            {
                value: 2,
                label: "Title",
            },
            {
                value: 3,
                label: "Salary",
            },
            {
                value: 4,
                label: "O*NET-SOC Code",
            },
        ];

        this.jobCategoryList = [{
                "value": 1,
                "label": "Administration / Management"
            },
            {
                "value": 2,
                "label": "Clerical / Office Support"
            },
            {
                "value": 3,
                "label": "Compliance And Regulation"
            },
            {
                "value": 4,
                "label": "Construction And Maintenance"
            },
            {
                "value": 5,
                "label": "Courts"
            },
            {
                "value": 6,
                "label": "Education / Public Information / Arts"
            },
            {
                "value": 7,
                "label": "Engineering / Scientific"
            },
            {
                "value": 8,
                "label": "Environmental / Agriculture / Natural Resources"
            },
            {
                "value": 9,
                "label": "Fiscal / Budget / Purchasing"
            },
            {
                "value": 10,
                "label": "Governors Office"
            },
            {
                "value": 11,
                "label": "Human Resources"
            },
            {
                "value": 12,
                "label": "Information Technology (Computers)"
            },
            {
                "value": 13,
                "label": "Institutions"
            },
            {
                "value": 14,
                "label": "Legal"
            },
            {
                "value": 15,
                "label": "Legislative"
            },
            {
                "value": 16,
                "label": "Public Health"
            },
            {
                "value": 17,
                "label": "Public Safety / Corrections"
            },
            {
                "value": 18,
                "label": "Sales / Services"
            },
            {
                "value": 19,
                "label": "Social Services / Counseling"
            },
            {
                "value": 20,
                "label": "Statistics / Research"
            }
        ];
    }

}

const jobService = new JobService();
export default jobService;