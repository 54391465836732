import React from "react";
import {joinClassNames} from "@dts-soldel/dts-react-common";

export default {
	iconAlert: className => <svg viewBox="0 0 44 40" className={joinClassNames("svg-icon", "svg-alert", className)} role="presentation"><path d="M22,30a2,2,0,1,0,2,2A2,2,0,0,0,22,30Zm21.74,7h0L23.72,1h0a2,2,0,0,0-3.41,0h0L.25,37h0A2,2,0,0,0,2,40H42a2,2,0,0,0,1.74-3ZM2,38,22,2,42,38ZM20,16a62.68,62.68,0,0,0,1,11,1,1,0,0,0,2,0,62.68,62.68,0,0,0,1-11,2.07,2.07,0,0,0-2-2A2.15,2.15,0,0,0,20,16Z"/></svg>,

	iconArrowRight: className => <svg viewBox="0 0 6.72 12.02" className={joinClassNames("svg-icon", "svg-arrow", className)} role="presentation"><polygon points="0.71 12.02 0 11.31 5.3 6.01 0 0.71 0.71 0 6.72 6.01 0.71 12.02"/></svg>,

	iconCalendar: className => <svg viewBox="0 0 23 25" className={joinClassNames("svg-icon", "svg-calendar", className)} role="presentation"><path d="M0,24a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V7H0ZM17,9h4v4H17Zm0,5h4v4H17Zm0,5h4v4H17ZM12,9h4v4H12Zm0,5h4v4H12Zm0,5h4v4H12ZM7,9h4v4H7Zm0,5h4v4H7Zm0,5h4v4H7ZM2,9H6v4H2Zm0,5H6v4H2Zm0,5H6v4H2ZM22,3H18V0H16V3H7V0H5V3H1A1,1,0,0,0,0,4V6H23V4A1,1,0,0,0,22,3Z"/></svg>,

	iconChevronRight: className => <svg className={joinClassNames("svg-icon", "svg-chevron-right", className)} role="presentation"  viewBox="0 0 12 12"><g><path d="M4.646 2.146a.5.5 0 0 0 0 .708L7.793 6L4.646 9.146a.5.5 0 1 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z"/></g></svg>,

    iconArrowLeft: className => <svg className={joinClassNames("svg-icon", "svg-left-arrow", className)} viewBox="0 0 24 24" role="presentation"><g><path d="M10.295 19.716a1 1 0 0 0 1.404-1.425l-5.37-5.29h13.67a1 1 0 1 0 0-2H6.336L11.7 5.714a1 1 0 0 0-1.404-1.424l-6.924 6.822a1.25 1.25 0 0 0 0 1.78l6.924 6.823z"/></g></svg>,

    iconPrinter: className => <svg className={joinClassNames("svg-icon", "svg-printer", className)} viewBox="0 0 24 24"><g><path d="M15.752 3a2.25 2.25 0 0 1 2.25 2.25v.753h.75a3.254 3.254 0 0 1 3.252 3.25l.003 5.997a2.249 2.249 0 0 1-2.248 2.25H18v1.25A2.25 2.25 0 0 1 15.75 21h-7.5A2.25 2.25 0 0 1 6 18.75V17.5H4.25A2.25 2.25 0 0 1 2 15.25V9.254a3.25 3.25 0 0 1 3.25-3.25l.749-.001L6 5.25A2.25 2.25 0 0 1 8.25 3h7.502zm-.002 10.5h-7.5a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h7.5a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-.75-.75zm3.002-5.996H5.25a1.75 1.75 0 0 0-1.75 1.75v5.996c0 .414.336.75.75.75H6v-1.75A2.25 2.25 0 0 1 8.25 12h7.5A2.25 2.25 0 0 1 18 14.25V16h1.783a.749.749 0 0 0 .724-.749l-.003-5.997a1.754 1.754 0 0 0-1.752-1.75zm-3-3.004H8.25a.75.75 0 0 0-.75.75l-.001.753h9.003V5.25a.75.75 0 0 0-.75-.75z"/></g></svg>,

    iconShare: className => <svg className={joinClassNames("svg-icon", "svg-share", className)} viewBox="0 0 24 24"><g><path d="M17 2.498a3.502 3.502 0 1 1-2.597 5.851l-4.558 2.604a3.5 3.5 0 0 1 0 2.093l4.557 2.606a3.502 3.502 0 1 1-.745 1.302L9.1 14.347a3.502 3.502 0 1 1 0-4.698l4.557-2.604A3.502 3.502 0 0 1 17 2.498zm0 13.5a2.002 2.002 0 1 0 0 4.004a2.002 2.002 0 0 0 0-4.004zm-10.498-6a2.002 2.002 0 1 0 0 4.004a2.002 2.002 0 0 0 0-4.004zm10.498-6a2.002 2.002 0 1 0 0 4.004a2.002 2.002 0 0 0 0-4.004z"/></g></svg>,

    iconExternalLink: className => <svg viewBox="0 0 9 9" className={joinClassNames("svg-icon", "svg-external-link", className)} aria-hidden="true" role="presentation"><polyline points="8.5 5.05 8.5 8.5 0.5 8.5 0.5 0.5 3.93 0.5"></polyline><line x1="8.24" y1="0.76" x2="4.76" y2="4.24"></line><polyline points="8.5 3.55 8.5 0.5 5.45 0.5"></polyline></svg>,

    iconInfo: className => <svg viewBox="0 0 24 24" className={joinClassNames("svg-icon", "svg-info", className)} role="presentation"><g><path d="M12 1.999c5.524 0 10.002 4.478 10.002 10.002c0 5.523-4.478 10.001-10.002 10.001c-5.524 0-10.002-4.478-10.002-10.001C1.998 6.477 6.476 1.999 12 1.999zm-.004 8.25a1 1 0 0 0-.992.885l-.007.116l.003 5.502l.007.117a1 1 0 0 0 1.987-.002L13 16.75l-.003-5.501l-.007-.117a1 1 0 0 0-.994-.882zM12 6.5a1.251 1.251 0 1 0 0 2.503A1.251 1.251 0 0 0 12 6.5z"/></g></svg>,

	iconMinus: className => <svg viewBox="0 0 11 11" className={joinClassNames("svg-icon", "svg-minus", className)} role="presentation"><rect x="5" width="1" height="11" transform="translate(0 11) rotate(-90)"/></svg>,

	iconPlus: className => <svg viewBox="0 0 12 12" className={joinClassNames("svg-icon", "svg-plus", className)} role="presentation"><g><path d="M5.91 2.008L6 2a.5.5 0 0 1 .492.41l.008.09v3h3a.5.5 0 0 1 .492.41L10 6a.5.5 0 0 1-.41.492L9.5 6.5h-3v3a.5.5 0 0 1-.41.492L6 10a.5.5 0 0 1-.492-.41L5.5 9.5v-3h-3a.5.5 0 0 1-.492-.41L2 6a.5.5 0 0 1 .41-.492L2.5 5.5h3v-3a.5.5 0 0 1 .41-.492z"/></g></svg>,

	iconQuestion: className => <svg className={joinClassNames("svg-icon", "svg-question", className)} viewBox="0 0 16 16" role="presentation"><g><path d="M8 2a6 6 0 1 1 0 12A6 6 0 0 1 8 2zm0 1a5 5 0 1 0 0 10A5 5 0 0 0 8 3zm0 7.5A.75.75 0 1 1 8 12a.75.75 0 0 1 0-1.5zm0-6a2 2 0 0 1 2 2c0 .73-.212 1.14-.754 1.708l-.264.27C8.605 8.87 8.5 9.082 8.5 9.5a.5.5 0 0 1-1 0c0-.73.212-1.14.754-1.708l.264-.27C8.895 7.13 9 6.918 9 6.5a1 1 0 0 0-2 0a.5.5 0 0 1-1 0a2 2 0 0 1 2-2z"/></g></svg>,

	iconSearch: className => <svg className={joinClassNames("svg-icon", "svg-search", className)} viewBox="0 0 24 24"><g><path d="M10 2.5a7.5 7.5 0 0 1 5.964 12.048l4.743 4.745a1 1 0 0 1-1.32 1.497l-.094-.083l-4.745-4.743A7.5 7.5 0 1 1 10 2.5zm0 2a5.5 5.5 0 1 0 0 11a5.5 5.5 0 0 0 0-11z"/></g></svg>,

	iconStar: className => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 30.58" className={joinClassNames("svg-icon", "svg-star", className)} role="presentation"><g><path d="M16,26.08,7.78,30.4a1.53,1.53,0,0,1-2.23-1.62l1.57-9.15L.47,13.15a1.54,1.54,0,0,1,.85-2.63l9.19-1.34L14.62.86a1.54,1.54,0,0,1,2.76,0l4.11,8.32,9.19,1.34a1.54,1.54,0,0,1,.85,2.63l-6.65,6.48,1.57,9.15a1.53,1.53,0,0,1-2.23,1.62Z"/></g></svg>,

	iconX: className => <svg viewBox="0 0 12 12" className={joinClassNames("svg-icon", "svg-x", className)} role="presentation"><g><path d="M1.897 2.054l.073-.084a.75.75 0 0 1 .976-.073l.084.073L6 4.939l2.97-2.97a.75.75 0 1 1 1.06 1.061L7.061 6l2.97 2.97a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L6 7.061l-2.97 2.97A.75.75 0 1 1 1.97 8.97L4.939 6l-2.97-2.97a.75.75 0 0 1-.072-.976l.073-.084l-.073.084z"/></g></svg>,
	
	iconLock: className => <svg viewBox="0 0 24 24" className={joinClassNames("svg-icon", "svg-lock", className)} role="presentation"><g><path d="M13.995 11a2 2 0 0 1-1.245 1.852v2.398a.75.75 0 0 1-1.5 0v-2.394A2 2 0 1 1 13.995 11z"/><path d="M3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5zm.75 6V6.478c2.577-.152 5.08-1.09 7.5-2.8c2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z"/></g></svg>,

    iconUser: className => <svg viewBox="0 0 20 20" className={joinClassNames("svg-icon", "svg-user", className)} role="presentation"><g><path d="M6.494 8.075c.045-.295.339-.575.754-.575s.709.28.754.575a.5.5 0 1 0 .988-.15C8.863 7.09 8.104 6.5 7.248 6.5s-1.615.59-1.742 1.425a.5.5 0 1 0 .988.15z"/><path d="M12.748 7.5c-.415 0-.708.28-.754.575a.5.5 0 0 1-.988-.15c.127-.835.886-1.425 1.742-1.425s1.615.59 1.742 1.425a.5.5 0 0 1-.988.15c-.046-.295-.339-.575-.754-.575z"/><path d="M5.495 10a.5.5 0 0 0-.497.549C5.236 12.992 7.154 15 9.995 15c2.841 0 4.76-2.008 4.998-4.451a.5.5 0 0 0-.498-.549h-9zm4.5 4c-2.081 0-3.516-1.303-3.912-3h7.825c-.397 1.697-1.831 3-3.913 3z"/><path d="M10 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16zm-7 8a7 7 0 1 1 14 0a7 7 0 0 1-14 0z"/></g></svg>,

    iconTriangle: className => <svg viewBox="0 0 24 24" className={joinClassNames("svg-icon", "svg-triangle", className)} role="presentation"><g><path d="M5.161 10.073C4.454 9.265 5.028 8 6.102 8h11.796c1.074 0 1.648 1.265.941 2.073l-5.522 6.31a1.75 1.75 0 0 1-2.634 0l-5.522-6.31zM6.653 9.5l5.159 5.896a.25.25 0 0 0 .376 0l5.16-5.896H6.652z"/></g><rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" /></svg>,

    iconCube: className => <svg viewBox="0 0 24 24" className={joinClassNames("svg-icon", "svg-cube", className)} role="presentation"><g><path d="M6.049 7.984a.75.75 0 0 1 .967-.435L12 9.439l4.984-1.89a.75.75 0 1 1 .532 1.402L12.75 10.76v5.491a.75.75 0 0 1-1.5 0v-5.49L6.484 8.95a.75.75 0 0 1-.435-.967z" /><path d="M10.591 2.512a3.75 3.75 0 0 1 2.818 0l7.498 3.04A1.75 1.75 0 0 1 22 7.174v9.652a1.75 1.75 0 0 1-1.093 1.622l-7.498 3.04a3.75 3.75 0 0 1-2.818 0l-7.498-3.04A1.75 1.75 0 0 1 2 16.826V7.175a1.75 1.75 0 0 1 1.093-1.622l7.498-3.04zm2.254 1.39a2.25 2.25 0 0 0-1.69 0l-7.499 3.04a.25.25 0 0 0-.156.232v9.652a.25.25 0 0 0 .156.232l7.499 3.04a2.25 2.25 0 0 0 1.69 0l7.499-3.04a.25.25 0 0 0 .156-.232V7.175a.25.25 0 0 0-.156-.232l-7.499-3.04z" /></g></svg>
};
