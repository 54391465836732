import PropTypes from "prop-types";
import React, { createContext } from "react";
import RootStore from "./RootStore";

const RootStoreContext = createContext(RootStore);

const propTypes = {
    children: PropTypes.node,
};
const defaultProps = {};

const RootStoreContextProvider = ({ children }) => <RootStoreContext.Provider value={RootStore}>{children}</RootStoreContext.Provider>;

RootStoreContextProvider.propTypes = propTypes;
RootStoreContextProvider.defaultProps = defaultProps;

export default RootStoreContextProvider;
