import {observer} from "mobx-react";
import React from "react";
import useRootStoreContext from "../../app/store/useRootStoreContext";
import HomeSearch from "./HomeSearch";
import HomeSearchResult from "./HomeSearchResult";
import {AjaxSpinner} from "@dts-soldel/dts-react-common";

const propTypes = {};
const defaultProps = {};

const Home = () => {
    const {UiStore} = useRootStoreContext();

    const getHomeUi = () => {
        if (UiStore.showWelcomeMessage) {
            return (
                <>
                    <h2>Welcome</h2>
                    <p>You may search here for most of the active job descriptions found within Utah
                        state government. Each description broadly defines important features of a job
                        such as the purpose, tasks, and minimum requirements; it also documents the pay
                        range, and information used for federal or state compliance, including ADA and
                        FLSA, Utah Code or licensure requirements. The job description is not
                        all-inclusive and may vary depending on the specific nature of the position.
                        Agencies may modify the tasks and KSAs based on a current position analysis.</p>
                </>
            );
        } else {
            return (
                <>
                    <HomeSearchResult/>
                </>
            );
        }
    }

    return (
        <div key="home" className="home-wrapper">
            <div className="home-logo">
                <a href="/" title="Homepage">
                <img
                    src="img/DHRM-Color.svg"
                    alt="DHRM Logo"
                    aria-hidden="true"
                    role="presentation"
                    label="Division of Human Resource Management - Job Descriptions Search Tool"
                />
                </a>
                <h1>
                    Job Description Search
                </h1>
            </div>
            <div className="home-content--layout">
                <>
                    <div
                        className={UiStore.searchCollapsed ? "home-content--layout left collapsed" : "home-content--layout left"}>
                        <HomeSearch/>
                    </div>
                    <div className="home-content--layout right">
                        {getHomeUi()}
                    </div>
                </>
            </div>
        </div>
    );
}

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

export default observer(Home);
