import {ValidationEngine, ValidationType, ValidationRules, isEmpty} from "@dts-soldel/dts-react-common";

const isMinLength = (val) => val.length >= 2;

export const formValidations = {
    selectedJobTitle: [
        ValidationRules[ValidationType.CUSTOM_CALLBACK]((data) => {
                return isMinLength(data) || (isEmpty(data));
            },
            () => `Title must be at least 2 characters long`
        ),
    ],
};

export default () => new ValidationEngine(formValidations);