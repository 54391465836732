const SearchType = {
    JOB_ID: "jobId",
    JOB_ONET_CODE: "jobOnetCode",
    JOB_TITLE: "jobTitle",
    JOB_CATEGORY: "jobCategory",
    JOB_TITLE_CATEGORY: "jobTitleCategory",
    NO_SEARCH: "none",
};

export {SearchType};
