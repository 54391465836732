import Home from "../../pages/Home/Home";
import JobDetail from "../../pages/JobDetail/JobDetail";

const Pages = {
	// public
	home: {
		Component: Home,
		pageParameterValue: "",
		title: "Home",
	},
    //Tom"s Results Placeholder
    detail: {
		Component: JobDetail,
		pageParameterValue: "resultsPage",
		title: "Job Detail",
	},
};
export default Pages;
