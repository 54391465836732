import React from "react";
import {
    Button
} from "@dts-soldel/dts-react-common";
import {useHistory, useParams} from "react-router-dom";


const propTypes = {};
const defaultProps = {};

const JobNotFound = () => {
    const {jobId} = useParams();
    const history = useHistory();
    let errorMessage = `No matching job found for job id ${jobId}`;

    const doRedirect = () => {
        history.push("/");
    };

    return (<div className="home-wrapper">
        <div className="home-logo">
            <img src="img/DHRM-Color.svg" alt="DHRM Logo" aria-hidden="true" role="presentation"/>
            <h1 className="visually-hidden">Division of Human Resource Management - Job Descriptions Tool</h1>
        </div>
        <div className="results-content--layout">
            <div>
                <h4>{errorMessage}</h4><br/>
                <p>Click below to go to job search page.</p><br/>
                <Button className="button--large outline" label="Return to Job Search" onClick={doRedirect}/>
            </div>
        </div>
    </div>);

};

JobNotFound.propTypes = propTypes;
JobNotFound.defaultProps = defaultProps;

export default JobNotFound;
