import { useEffect, useRef } from "react";

export default ({ whichKeyCode, onKeyDown }) => {
    const keydownFuncRef = useRef();
    useEffect(() => {
        keydownFuncRef.current = e => {
            if (e.code === whichKeyCode || e.keyCode === whichKeyCode || e.key === whichKeyCode) {
                onKeyDown(e);
            }
        };
        document.addEventListener("keydown", keydownFuncRef.current);
        
        return () => {
            document.removeEventListener("keydown", keydownFuncRef.current);
            keydownFuncRef.current = null;
        };
    }, []);
};  
