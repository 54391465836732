import { makeAutoObservable } from "mobx";
import AuthPhases, {
  isAuthFlowEnded,
  isAuthFlowSilentRenew,
} from "./AuthPhases";

export default class SecurityStore {
  // the logged in user from the GQL API
  apiUser = null;

  // the logged in user from OpenID
  loggedInUser = null;

  // the jwt for the user
  jwt = null;

  // true when oidc is fetching user information
  isFetchingUserInfo = false;

  // default phase for login progress
  authPhase = AuthPhases.BEGIN;

  // when phase changes, is the flow finished?
  isAuthPhaseEnded = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    Object.defineProperty(this, "rootStore", rootStore);
  }

  setAuthPhase(authPhase) {
    if (authPhase !== this.authPhase) {
      this.authPhase = authPhase;
      this.isAuthPhaseEnded = isAuthFlowEnded(authPhase);
      this.isAuthPhaseSilentRenew = isAuthFlowSilentRenew(authPhase);
    }
  }
}
