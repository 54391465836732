import {CheckBox, Button, PopupWindow, AjaxSpinner} from "@dts-soldel/dts-react-common";
import React, {useState, useEffect, useRef} from "react";
import Icon from "../../misc/Icon";
import {useParams, Link} from "react-router-dom";
import {useLazyQuery} from "@apollo/client";
import {getJobDetailById} from "../../app/webservice/gql/JobServiceGQL";
import moment from "moment-timezone";
import {useHistory} from "react-router-dom";

const propTypes = {};
const defaultProps = {};

const JobDetail = () => {

    const {jobId} = useParams();
    const history = useHistory();

    const [getJobDetailData, {
        loading: jobDetailLoading,
        called: jobDetailCalled,
        data: jobDetailData
    }] = useLazyQuery(getJobDetailById, {fetchPolicy: "no-cache"});
    const [job, setJob] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [agencyList, setAgencyList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [otherList, setOtherList] = useState([]);
    const [showJobDetailPrint, setShowJobDetailPrint] = useState(false);
    const [excludeKSA, setExcludeKSA] = useState(false);
    const [printJob, setPrintJob] = useState(false);

    const printJobRef = useRef(false);

    if (!jobDetailCalled) {
        getJobDetailData({
            variables: {
                jobId: parseInt(jobId)
            }
        });
    }

    useEffect(() => {
        if (!jobDetailLoading && jobDetailData && jobDetailData.searchJobById.length > 0) {
            jobDetailData.searchJobById.map(job => {
                setJob(job);
                setCategoryList(job.jobCategory);
                setAgencyList(job.jobAgency);
                setTaskList(job.jobTask);
                setSkillList(job.jobKSA);
                setOtherList(job.jobOther);
            });
        } else if (!jobDetailLoading && jobDetailData && jobDetailData.searchJobById.length === 0) {
            history.push(`/jobNotFound/${jobId}`);
        }
    }, [jobDetailLoading]);

    useEffect(() => {
        if (!printJobRef.current && printJob) {
            window.print();
            printJobRef.current = false;
            setPrintJob(false);
        }
        printJobRef.current = printJob;
    }, [printJob]);

    useEffect(() => {
        if (!printJobRef.current && printJob) {
            window.print();
            printJobRef.current = false;
            setPrintJob(false);
        }
        printJobRef.current = printJob;
    }, [printJob]);

    const openPrintPopup = (event) => {
        event.preventDefault();
        setShowJobDetailPrint(true);
    };

    const closePrintPopup = () => {
        setShowJobDetailPrint(false);
    };

    const doJobPrint = () => {
        // e.preventDefault();
        setShowJobDetailPrint(false);
        setPrintJob(true);
    };

    const loadCategoryList = () => {
        if (categoryList.length > 0) {
            return categoryList.map((category, index) => {
                return (
                    <li key={index}>{category.name}</li>
                );
            });
        } else {
            return (
                <li>None</li>
            );
        }
    };

    const loadAgencyList = () => {
        if (agencyList.length > 0) {
            if (agencyList.length <= 5) {
                return agencyList.map((agency, index) => {
                    return (
                        <li key={index}>{agency.agencyId} - {agency.name}</li>
                    );
                });
            } else {
                return (
                    <li>{agencyList.length} Agencies</li>
                );
            }
        } else {
            return (
                <li>None</li>
            );
        }
    };

    const loadTaskList = () => {
        if (taskList.length > 0) {
            return taskList.map((task, index) => {
                return (
                    <li key={index}>{task.taskDescription}</li>
                );
            });
        } else {
            return (
                <li>None</li>
            );
        }
    };

    const loadSkillList = () => {
        if (skillList.length > 0) {
            return skillList.map((skill, index) => {
                return (
                    <li key={index}>
                        <b>{skill.categoryName}: {skill.onetElementId} - {skill.onetItem}</b>
                        <p>{skill.description}</p>
                    </li>
                );
            });
        } else {
            return (
                <li>None</li>
            );
        }
    };

    const loadOtherList = () => {
        if (otherList.length > 0) {
            return otherList.map((other, index) => {
                return (
                    <tr className="other-requirement" key={index}>
                        <td>{other.otherTitle}</td>
                        <td>{other.otherComment}</td>
                    </tr>
                );
            });
        } else {
            return (
                <li>None</li>
            );
        }
    };

    const getProbationDescription = () => {
        if (job.equalOpportunityDesignation.length > 0 && job.probationMonths > 0) {
            return <ul>{job.equalOpportunityDesignation} ({job.probationMonths} Months)</ul>;
        }
    };

    const getBenchMarkFamilyInfo = () => {
        if (job?.isBenchmark === "Y") {
            if (job?.benchmarkFamilyTitle !== null) {
                return (
                    <>
                        <li>
                            <b>Is Benchmark Job</b>
                            Yes
                        </li>
                        <li>
                            <b>Benchmark Family</b>
                            {job?.benchmarkFamilyTitle}
                        </li>
                    </>
                );
            } else {
                return (
                    <li>
                        <b>Is Benchmark Job</b>
                        Yes
                    </li>
                );
            }
        } else if (job?.isBenchmark === "N") {
            if (job?.benchmarkJobTitle !== null) {
                return (
                    <>
                        <li>
                            <b>Is Benchmark Job</b>
                            No
                        </li>
                        <li>
                            <b>Benchmark Job</b>
                            {job?.benchmarkJobId} - {job?.benchmarkJobTitle}
                        </li>
                    </>
                );
            } else {
                return (
                    <li>
                        <b>Is Benchmark Job</b>
                        No
                    </li>
                );
            }
        }
    };

    const formatDate = (date) => {
        return moment(date).format("MM-DD-YYYY");
    };

    if (!job) {
        return (<div className="home-wrapper">
            <div className="home-logo">
                <img src="img/DHRM-Color.svg" alt="DHRM Logo" aria-hidden="true" role="presentation"/>
                <h1 className="">Job Description Search</h1>
            </div>
            <div className="results-content--layout">
                <div className="results-content--layout right">
                    <AjaxSpinner size={30}/>
                </div>
            </div>
        </div>);
    } else if (job) {
        return (
            <div id="top" className="home-wrapper">
                {showJobDetailPrint && <PopupWindow
                    className="popup-window"
                    footerChildren={[
                        <Button className="printoutline" label="Cancel" key="cancel-button" onClick={closePrintPopup}/>,
                        <Button className="mainPrint" label="Print" key="print-button" onClick={doJobPrint}/>
                    ]}>
                    <div>
                        <h3>Print Options</h3>
                        <CheckBox field={"excludeKSA"}
                                  value={excludeKSA}
                                  label={"Exclude: Knowledge, Skills and Abilities (KSAs)"}
                                  onChange={(field, value) => setExcludeKSA(value)}/>
                    </div>
                </PopupWindow>}
                <div className="home-logo">
                    <img src="img/DHRM-Color.svg" alt="DHRM Logo" aria-hidden="true" role="presentation"/>
                    <h1 className="visually-hidden">Division of Human Resource Management - Job Descriptions Tool</h1>
                </div>
                <div className="results-content--layout">
                    <div className="results-content--layout left">
                        <div className="menu--fixed">
                            <ul>
                                <li>
                                    <Link className="back" to="/">Back to results</Link>
                                </li>
                                <li><a href={`detail/${jobId}#overview`}>Overview</a></li>
                                <li><a href={`detail/${jobId}#tasks`}>Example of Tasks</a></li>
                                <li><a href={`detail/${jobId}#ksa`}>Knowledge, Skills, Abilities</a></li>
                                <li><a href={`detail/${jobId}#other`}>Other Requirements</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="results-content--layout right">
                        <div className="page-container">
                            <div className="position-title" id="overview">
                                <div className="position-controls">
                                    <a href="" onClick={openPrintPopup}>{Icon.iconPrinter()} Print</a>
                                </div>
                                <h1>{job?.jobTitle}</h1>
                            </div>
                            <div id="purpose">
                                <h2>Purpose and Distinguishing Characteristics</h2>
                                <p>{job?.purpose}</p>
                            </div>
                            <div id="tasks">
                                <h2>Example of Tasks</h2>
                                <ul>
                                    {loadTaskList()}
                                </ul>
                            </div>
                            <div className={excludeKSA ? "no-print" : null}>
                                <h2 id="ksa">Knowledge, Skills, and Abilities (KSAs)</h2>
                                <ul>
                                    {loadSkillList()}
                                </ul>
                            </div>
                            <div id="other">
                                <h2>Other Requirements</h2>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>DESCRIPTION</th>
                                        <th>CLARIFICATION (If Applicable)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loadOtherList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="page-container-sidebar">
                            <ul>
                                <li>
                                    <b>Job Id</b>
                                    {job?.jobId}
                                </li>
                                <li>
                                    <b>Salary Range</b>
                                    ${job?.payRateMinHourlyRate.toFixed(2)} - ${job?.payRateMaxHourlyRate.toFixed(2)}
                                </li>
                                <li>
                                    <b>Working Level</b>
                                    {job?.workingLevel}
                                </li>
                                <li>
                                    <b>Supervisory Level</b>
                                    {job?.supervisorLevel}
                                </li>
                                <li>
                                    <b>Agency</b>
                                    <ul>
                                        {loadAgencyList()}
                                    </ul>
                                </li>
                                <li>
                                    <b>Status</b>
                                    {job?.status}
                                </li>
                                <li>
                                    <b>Job Categories</b>
                                    <ul>
                                        {loadCategoryList()}
                                    </ul>
                                </li>
                                <li>
                                    <b>FLSA Status</b>
                                    {job?.flsaStatus}
                                </li>
                                {getBenchMarkFamilyInfo()}
                                <li>
                                    <b>Effective Date</b>
                                    {formatDate(job?.effectiveDate.value)}
                                    {/*{job?.effectiveDate.value}*/}
                                </li>
                                <li>
                                    <b>O*NET-SOC Code</b>
                                    {job?.jobCode}
                                </li>
                                <li>
                                    <b>Classified</b>
                                    {job?.classified}
                                </li>
                                <li>
                                    <b>EEO ID &amp; Probationary Period</b>
                                    {getProbationDescription()}
                                </li>
                                <li>
                                    <b>Working Conditions</b>
                                    {job?.workingCondtion}
                                </li>
                                <li>
                                    <b>Highly Sensitive</b>
                                    {job?.safetySensitive}
                                </li>
                                <li>
                                    <b>Physical Requirements</b>
                                    {job?.physicalRequirement}
                                </li>
                            </ul>
                        </div>
                        <div className="page-container--controls">
                            <a className="back-to-top" href={`detail/${jobId}#top`}>Back to Top</a>
                            {/*<button>Back to Top</button>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

JobDetail.propTypes = propTypes;
JobDetail.defaultProps = defaultProps;

export default JobDetail;
