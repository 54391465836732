const AuthPhases = {
    // if there is an error in the chain, this is the state
    LOGIN_ERROR: "LOGIN_ERROR: There was an error doing login",
    API_FETCH_ERROR: "API_FETCH_ERROR: There was an error when fetching the user from the GQL api",

    // default starting state
    BEGIN: "BEGIN: pre any auth attempt",
    UNPROTECTED: "UNPROTECTED: no jwt found so no login work attempted",
    EXPIRED: "EXPIRED: the current user jwt is expired",

    CHECKING_LOCAL_STORAGE_JWT: "CHECKING_LOCAL_STORAGE_JWT: check for jwt in local storage",
    REDIRECTING_TO_LOGIN: "REDIRECTING_TO_LOGIN: redirecting user to login page",
    FETCHING_USERINFO_FROM_LOGIN: "FETCHING_USERINFO_FROM_LOGIN: use jwt to fetch login details from login oauth",
    FETCHED_USERINFO_FROM_LOGIN: "FETCHED_USERINFO_FROM_LOGIN: done fetching login details from login oauth",

    STARTING_SIGNIN_REDIRECT_CALLBACK: "STARTING_SIGNIN_REDIRECT_CALLBACK: starting the sign in callback processing",
    RUNNING_SIGNIN_REDIRECT_CALLBACK: "RUNNING_SIGNIN_REDIRECT_CALLBACK: loading user from jwt in url",
    ENDED_SIGNIN_REDIRECT_CALLBACK: "ENDED_SIGNIN_REDIRECT_CALLBACK: ended the sign in callback processing",

    NAV_TO_LAST_PAGE_SOON: "NAV_TO_LAST_PAGE_SOON: timer set, going to nav soon",
    NAV_TO_LAST_PAGE_DONE: "NAV_TO_LAST_PAGE_DONE: done navigating (ie, already at correct location)",
    FETCHING_USERINFO_FROM_API: "FETCHING_USERINFO_FROM_API: use jwt to fetch login details from GQL api",
    FETCHED_USERINFO_FROM_API: "FETCHED_USERINFO_FROM_API: done fetching login details from GQL api",

    SILENT_RENEWAL_BEGIN: "SILENT_RENEWAL_BEGIN: page opened as silent renewal iframe",
    SILENT_RENEWAL_RESPONDING: "SILENT_RENEWAL_RESPONDING: silent response sent",
    SILENT_RENEWAL_END: "SILENT_RENEWAL_END: page finished being silent",
    SILENT_ERROR: "SILENT_ERROR: got an error in silent renew process",
};

export const isAuthFlowEnded = authPhase => (
    [
        AuthPhases.NAV_TO_LAST_PAGE_DONE,
    ].includes(authPhase)
);

export const isAuthFlowError = authPhase => (
    [
        AuthPhases.LOGIN_ERROR,
        AuthPhases.API_FETCH_ERROR,
        AuthPhases.SILENT_ERROR,
    ].includes(authPhase)
);

export const isAuthFlowSilentRenew = authPhase => (
    [
        AuthPhases.SILENT_RENEWAL_BEGIN,
        AuthPhases.SILENT_ERROR,
        AuthPhases.SILENT_RENEWAL_END,
    ].includes(authPhase)
);

export default AuthPhases;
