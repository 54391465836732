import { observer } from "mobx-react";
import React from "react";
import Helmet from "react-helmet";
import useRootStoreContext from "./store/useRootStoreContext";

const propTypes = {};
const defaultProps = {};

const HeadTags = () => {
	const {UiStore} = useRootStoreContext();

	const pageTitle = UiStore.pageTitle;
	return (
		<Helmet>
			<title>{UiStore.pageTitle ? UiStore.pageTitle : ""}</title>
		</Helmet>
	);
};

HeadTags.propTypes = propTypes;
HeadTags.defaultProps = defaultProps;

export default observer(HeadTags);
