import React, {useState, useEffect} from "react";
import Icon from "../../misc/Icon";
import useRootStoreContext from "../../app/store/useRootStoreContext";
import {observer} from "mobx-react";
import {action} from "mobx";
import {SelectInput, Size, AjaxSpinner} from "@dts-soldel/dts-react-common";
import jobService from "../../app/services/JobService";
import {useHistory} from "react-router-dom";

const propTypes = {};
const defaultProps = {};

const HomeSearchResult = () => {
    const {UiStore} = useRootStoreContext();
    const history = useHistory();
    let pages = [];

    const resultCount = UiStore.searchResultsCount;
    const pageSize = 25;
    const TABLET_WIDTH = 768;
    const lastPageSize = resultCount % pageSize;
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [pageNumberLimit] = useState(3);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

    const [windowWidth, setWindowWidth] = useState(window.outerWidth);

    useEffect(() => {
        const remainder = resultCount % pageSize;
        let newPageCount = Math.floor(resultCount / pageSize);
        if (remainder > 0) {
            newPageCount++;
        }
        setPageCount(newPageCount);
    }, [pageCount, resultCount, UiStore.searchResultsCount]);

    useEffect(() => {
        if (Number(UiStore.lastViewedJobPage) !== 0) {
            setCurrentPage(Number(UiStore.lastViewedJobPage));
            setMaxPageNumberLimit(Number(UiStore.lastMaxPage));
            setMinPageNumberLimit(Number(UiStore.lastMinPage));
        } else {
            setCurrentPage(1);
            setMaxPageNumberLimit(pageNumberLimit);
            setMinPageNumberLimit(0);
        }
    }, [UiStore.lastViewedJobPage]);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.outerWidth);
        }

        window.addEventListener('resize', handleResize);
    });

    const setLastMinMaxPageNumbers = () => {
        let myRemainder = pageCount % pageNumberLimit;

        if (myRemainder === 0) {
            setMaxPageNumberLimit(pageCount);
            setMinPageNumberLimit(pageCount - pageNumberLimit);
        } else {
            setMinPageNumberLimit(pageCount - myRemainder);
            setMaxPageNumberLimit(pageCount - myRemainder + pageNumberLimit);
        }
    };

    const selectJob = action((job) => {
        UiStore.searchResultsMessage = "";
        UiStore.lastViewedJobId = job.jobId;
        UiStore.lastViewedJobPage = currentPage;
        UiStore.lastMaxPage = maxPageNumberLimit;
        UiStore.lastMinPage = minPageNumberLimit;
        history.push(`/detail/${job.jobId}`);
    });

    const getSliceFrom = () => {
        let newFromIdx = 0;

        if (windowWidth <= TABLET_WIDTH) {
            if (UiStore.showResultsFirstPage) {
                setCurrentPage(1);
                setMinPageNumberLimit(0);
                setMaxPageNumberLimit(3);
                UiStore.showResultsFirstPage = false;
            }
            // showLastPage();
            if (currentPage === 1) {
                newFromIdx = 1;
            } else {
                newFromIdx = ((currentPage - 1) * pageSize) + 1;
            }
        } else {
            newFromIdx = 1;
        }

        return newFromIdx;
    };

    const getSliceTo = () => {
        let newToIdx = 0;

        if (windowWidth <= TABLET_WIDTH) {
            if (currentPage === 1 && currentPage === pageCount) {
                if (lastPageSize === 0) {
                    newToIdx = resultCount;
                } else {
                    newToIdx = lastPageSize;
                }
            } else if ((currentPage === 1 && currentPage < pageCount) || (currentPage > 1 && currentPage < pageCount)) {
                newToIdx = currentPage * pageSize;
            } else if (currentPage === pageCount) {
                newToIdx = resultCount;
            }
        } else {
            newToIdx = resultCount;
        }
        return newToIdx;
    };

    const handleSortResultsOnChange = (value) => {
        sortResults(value);
        // UiStore.uiCurrentPage = 1;
        setCurrentPage(1);
        setMaxPageNumberLimit(pageNumberLimit);
        setMinPageNumberLimit(0);
    };

    const cleanJobCode = (obj) => (obj && obj.replace(/-/g, "") || 0);

    const sortResults = action((sortType) => {
        switch (sortType) {
            case 1:
                UiStore.results.sort((a, b) => (a.jobId < b.jobId) ? -1 : (a.jobId > b.jobId) ? 1 : 0);
                break;
            case 2:
                UiStore.results.sort((a, b) => (a.jobTitle < b.jobTitle) ? -1 : (a.jobTitle > b.jobTitle) ? 1 : (a.jobId < b.jobId) ? -1 : (a.jobId > b.jobId) ? 1 : 0);
                break;
            case 3:
                UiStore.results.sort((a, b) => (a.payRateMinHourlyRate < b.payRateMinHourlyRate) ? -1 : (a.payRateMinHourlyRate > b.payRateMinHourlyRate) ? 1 : (a.jobId < b.jobId) ? -1 : (a.jobId > b.jobId) ? 1 : 0);
                break;
            case 4:
                // UiStore.results.sort((a, b) => ( cleanJobCode(a.jobCode) - cleanJobCode(b.jobCode) || a.jobId - b.jobId ));
                UiStore.results.sort((a, b) => (a.jobCode < b.jobCode) ? 1 : (a.jobCode > b.jobCode) ? -1 : (a.jobId < b.jobId) ? -1 : (a.jobId > b.jobId) ? 1 : 0);
                break;
            default:
                break;
        }
    });

    const loadSearchResults = action(() => {
        if (pageCount > 1) {
            pages = [];
            for (let i = 0; i < pageCount; i++) {
                pages.push(i + 1);
            }
        }
        return UiStore.results.slice(getSliceFrom() - 1, getSliceTo()).map((job, index) => {
            return (
                <div
                    className="search-results--item"
                    key={index}
                    onClick={() => selectJob(job)}
                >
                    <div className="item-id">
                        <div className="item-heading">Job ID</div>
                        <div className="item-value">{job.jobId}</div>
                    </div>
                    <div className="item-title">
                        <div className="item-heading">Job Title</div>
                        <div className="item-value">{job.jobTitle}</div>
                    </div>
                    <div className="item-salary">
                        <div className="item-heading">Salary Range</div>
                        <div className="item-value">
                            ${job.payRateMinHourlyRate.toFixed(2)} - ${job.payRateMaxHourlyRate.toFixed(2)}
                        </div>
                    </div>
                    <div className="item-code">
                        <div className="item-heading">O*NET-SOC Code</div>
                        <div className="item-value">{job.jobCode}</div>
                    </div>
                    <div className="item-action">{Icon.iconChevronRight()}</div>
                </div>
            );
        });
    });

    const handlePageClick = (e) => {
        setCurrentPage(Number(e.target.id));
    };

    const handleFirstbtn = () => {
        setCurrentPage(1);
        setMaxPageNumberLimit(pageNumberLimit);
        setMinPageNumberLimit(0);
    };

    const handlePrevbtn = () => {
        setCurrentPage(currentPage - 1);

        if ((currentPage - 1) % pageNumberLimit == 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };

    const handleNextbtn = () => {
        setCurrentPage(currentPage + 1);

        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const handleLastbtn = () => {
        setCurrentPage(pageCount);
        setLastMinMaxPageNumbers();
    };

    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li className="pagination-ellipsis" onClick={handlePrevbtn}> &hellip; </li>;
    }

    let pageIncrementBtn = null;
    if (pageCount > maxPageNumberLimit) {
        pageIncrementBtn = <li className="pagination-ellipsis" onClick={handleNextbtn}> &hellip; </li>;
    }

    return (
        <>
            {
                UiStore.resultsLoading
                    ? <AjaxSpinner size={30}/>
                    : null
            }
            {
                !UiStore.resultsLoading && !UiStore.searchResultsFound && UiStore.searchResultsMessage
                    ? <h4>{UiStore.searchResultsMessage}</h4>
                    : null
            }
            {
                UiStore.results.length > 0
                    ? <React.Fragment>
                        <div className="search-results--sorting">
                            <div className="search-results--sorting-results">
                                Viewing{" "}
                                <span>
                                <b>{getSliceFrom()}</b> - <b>{getSliceTo()}</b>
                            </span>
                                {" "}of{" "}
                                <span>
                                <b>{resultCount}</b>
                            </span>
                                {" "}Jobs
                            </div>
                            <div className="search-results--sorting-options">

                                <SelectInput
                                    label="Sort By"
                                    field="selectedSortOrder"
                                    options={jobService.sortBy}
                                    value={UiStore.selectedSortBy}
                                    placeholder=""
                                    onChange={(field, value) => {
                                        (UiStore.selectedSortBy = value);
                                        handleSortResultsOnChange(value);
                                    }}
                                    //handle creation of new options here
                                    size={Size.MEDIUM}
                                    multiSelect={false}
                                    allowClear={false}
                                />

                            </div>
                        </div>
                        <div className="search-results--list">{loadSearchResults()}</div>
                        {
                            windowWidth <= TABLET_WIDTH
                                ? <nav className="search-results--pagination" role="navigation"
                                       aria-label="Pagination Navigation">
                                    <ul className="pagination">
                                        <li className="page-item"><a
                                            className={pageCount <= pageNumberLimit || pageCount == 1 ? "hidden" : currentPage == 1 ? "page-link disabled" : "page-link"}
                                            aria-label="First Page" id="btnFirst"
                                            onClick={handleFirstbtn}>First</a></li>
                                        <li className="page-item"><a
                                            className={pageCount <= pageNumberLimit ? "hidden" : currentPage == 1 ? "page-link disabled" : "page-link"}
                                            aria-label="Previous Page" id="btnPrev"
                                            onClick={handlePrevbtn}>Prev</a></li>
                                        {pageDecrementBtn}
                                        {pages.map((number, index) => {
                                            if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
                                                return (
                                                    <li key={index} id={number} className="page-item">
                                                        <a id={number}
                                                           className={number == currentPage ? "page-link active" : "page-link"}
                                                           onClick={handlePageClick}>{number}</a>
                                                    </li>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                        {pageIncrementBtn}
                                        <li className="page-item"><a
                                            className={pageCount <= pageNumberLimit ? "hidden" : currentPage == pageCount ? "page-link disabled" : "page-link"}
                                            aria-label="Next Page" id="btnNext" onClick={handleNextbtn}>Next</a></li>
                                        <li className="page-item"><a
                                            className={pageCount <= pageNumberLimit || pageCount == 1 ? "hidden" : currentPage == pageCount ? "page-link disabled" : "page-link"}
                                            aria-label="Last Page" id="btnLast" onClick={handleLastbtn}>Last</a></li>
                                    </ul>
                                </nav>
                                : null
                        }

                    </React.Fragment>
                    : null
            }
            {
                (!UiStore.resultsLoading && UiStore.results.length === 0 && !UiStore.searchResultsMessage)
                    ? <h4>No job results</h4>
                    : null
            }
        </>
    );
};

HomeSearchResult.propTypes = propTypes;
HomeSearchResult.defaultProps = defaultProps;

export default observer(HomeSearchResult);
