import Pages from "../pages/Pages";

export default ({
    utahHeader,
}) => ({
    headerStyle: utahHeader?.HEADER_STYLE.DARK,
    headerTitle: "Division of Human Resource Management",
    headerShortTitle: "DHRM",
    headerTitleOnClick: Pages.home.forward,
});
